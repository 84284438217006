<template>
  <div style="overflow-x:auto" class="custom-scroll p-3">
    <div class="text-center mx-auto p-5" v-if="loading">
      <i class="fa fa-cog fa-spin fa-4x"></i>
    </div>
    <table class="table">
      <tbody>
      <tr>
        <td>Позывной</td>
        <td>{{ data.Target.Callsign }}</td>
      </tr>
      <tr>
        <td>Что поменялось</td>
        <td>{{ getActionTypeTitle }}</td>
      </tr>
      </tbody>
    </table>

    <div v-if="data.Type === 'legend' || data.Type === 'legend_master'">
      <table class="table">
        <tbody>
        <tr>
          <td colspan="2">Подробности изменений в легенде</td>
        </tr>
        <tr>
          <td colspan="2"> <div v-html="getLegendDiff" class="mb-3"></div></td>
        </tr>
        <tr>
          <td>До</td>
          <td>После</td>
        </tr>
        <tr>
          <td>{{data.PreviousVersion}}</td>
          <td>{{ data.CurrentVersion }}</td>
        </tr>
        </tbody>
      </table>
    </div>


  </div>
</template>

<script>
  import API from "../api/stalk.net";

  export default {
    name: "NotificationData",
    props: {
      data: Object,
      loading: Boolean
    },
    data() {
      return {};
    },
    computed: {
      getActionTypeTitle() {
        let self = this;
        return API.getActionTypeMessage(self.data.Type);
      },
      getLegendDiff() {
        const Diff = require('text-diff');
        const diff = new Diff();
        const textDiff = diff.main(this.data.PreviousVersion, this.data.CurrentVersion); // produces diff array
        diff.cleanupSemantic(textDiff)  // makes deleted text before added
        return diff.prettyHtml(textDiff); // produces a formatted HTML string
      }
    },
    methods: {}
  };
</script>

<style scoped>

  div >>> ins {
    color: green;
    text-decoration: none;
  }

  div >>> del {
    color: red;
    padding-right: 10px;
  }

</style>

<template>
  <div>
    <div style="overflow-x:auto" class="custom-scroll">
      <div class="text-center mx-auto p-5" v-if="isDataLoading">
        <i class="fa fa-cog fa-spin fa-4x"></i>
      </div>

      <table class="table table-hover table-striped table-responsive-md">
        <thead>
          <tr>
            <th
              scope="col"
              v-for="key in columns"
              @click="()=>sortBy(key)"
              :class="{ active: sortKey === key.title }"
            >
              {{ key.title | capitalize }}
              <span
                class="arrow"
                :class="sortOrders[key] > 0 ? 'asc' : 'dsc'"
              ></span>
            </th>
            <th v-if="actions.length > 0">
              Действия
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in filteredItems" :class="getTRClass(entry)">
            <td v-for="key in columns">
              <div
                v-if="typeof key.action === 'function'"
                @click="key.action(takePath(entry, index.id))"
                class="pointLink"
              >
                <a class="badge badge-info mr-1">
                  <i class="icon-info "></i>
                </a>
                {{ takePath(entry, key) }}
              </div>
              <div v-if="typeof key.action !== 'function'">
                {{ takePath(entry, key) }}
              </div>
            </td>
            <td>
              <slot name="notification" :notification="entry"></slot>

              <div
                class="btn-group dropleft"
                v-if="actions.length > 0"
                :disabled="isDataLoading"
              >
                <button
                  class="btn btn-primary btn-sm dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Действие
                </button>
                <div class="dropdown-menu">
                  <h6 class="dropdown-header">
                    {{ takePath(entry, index.title) }}
                  </h6>
                  <button
                    class="dropdown-item"
                    type="button"
                    v-for="action in actions"
                    :disabled="action.disabled || isDataLoading"
                    v-bind:key="action.name"
                    v-if="action.filter(entry)"
                    @click="action.action(entry)"
                  >
                    {{ action.title }}
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--    <Pagination-->
    <!--      :getFunc="getFunc"-->
    <!--      :pagination="pagination"-->
    <!--      v-on:load-page="rowData = $event"-->
    <!--      v-on:total-items="numberOfRows = $event"-->
    <!--      v-on:is-loading="isDataLoading = $event"-->
    <!--    >-->
    <!--    </Pagination>-->

    <h6 class="mb-3">Всего записей: {{ items.length }}</h6>
  </div>
</template>

<script>
// import Pagination from "./Pagination";
import Spinner from "./Spinner";
import NotificationData from '../NotificationData';

export default {
  name: "Grid",
  components: { NotificationData, Spinner },
  props: {
    items: Array,
    columns: Array,
    classes: Array,
    actions: Array,
    index: Object,
    filterKey: String,
    defaultSort: Object,
    defaultSortOrder: Number,
    isDataLoading: Boolean
  },

  data() {
    let self = this;
    let sortOrders = [];
    let sortKey = {};
    let sortOrder = self.defaultSortOrder*-1;

    //fill all sortOrders
    this.columns.forEach(c => {
      if (typeof c !== "undefined") {
        sortOrders[c.path] = sortOrder;
      }
    });

    return {
      sortKey: sortKey,
      sortOrders: sortOrders,
      sortOrder: sortOrder
    };
  },

  computed: {
    filteredItems() {
      let self = this;

      let sortKey = self.sortKey;
      let filterKey = self.filterKey && self.filterKey.toLowerCase();
      let order = self.sortOrders[sortKey.path];
      let items = self.items;

      if (filterKey) {
        //TODO doesn't work on such complicated objects
        items = items.filter(row => {
          return Object.keys(row).some(key => {
            return (
              String(row[key])
                .toLowerCase()
                .indexOf(filterKey) > -1
            );
          });
        });
      }
      if (sortKey) {
        items = items.slice().sort((a, b) => {
          a = self.takePath(a, sortKey);
          b = self.takePath(b, sortKey);
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }

      if (self.sortOrder < 0) {
        self.sortBy(sortKey); //force reverse order
        self.sortOrder = 1; //disable
      }
      return items;
    }
  },
  filters: {
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  },
  methods: {
    takePath(entry, key) {
      if (typeof key.path === "undefined") {
        return [];
      }
      let result = key.path.split(".").reduce(function(o, k) {
        return o && o[k];
      }, entry);

      if (typeof key.handler === "function") {
        return key.handler(result);
      }

      return result;
    },
    getTRClass(entry) {
      let self = this;

      for (let c of self.classes) {
        if (self.takePath(entry, c) === c.equals) {
          return c.type;
        }
      }
      return "";
    },
    sortBy(key) {
      this.sortKey = key;
      if (typeof key.path === "undefined") return;
      this.sortOrders[key.path] = this.sortOrders[key.path] * -1;
    }
  }
};
</script>

<style scoped>
.custom-scroll::-webkit-scrollbar {
  height: 4px;
  border-radius: 5px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  background: #2f353a;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  background: #495156;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #121517;
}

/*For Firefox*/
.custom-scroll {
  scrollbar-width: thin;
  scrollbar-color: #495156 #2f353a;
}

.table-green,
.table-green > th,
.table-green > td {
  background-color: #123d1c;
}

.table-green th,
.table-green td,
.table-green thead th,
.table-green tbody + tbody {
  border-color: #123d1c;
}

.table-hover .table-green:hover {
  background-color: #195c24;
}

.table-hover .table-green:hover > td,
.table-hover .table-green:hover > th {
  background-color: #195c24;
}

.table-red,
.table-red > th,
.table-red > td {
  background-color: #3d0d06;
}

.table-red th,
.table-red td,
.table-red thead th,
.table-red tbody + tbody {
  border-color: #3d0d06;
}

.table-hover .table-red:hover {
  background-color: #681906;
}

.table-hover .table-red:hover > td,
.table-hover .table-red:hover > th {
  background-color: #681906;
}

.table-yellow,
.table-yellow > th,
.table-yellow > td {
  background-color: #494901;
}

.table-yellow th,
.table-yellow td,
.table-yellow thead th,
.table-yellow tbody + tbody {
  border-color: #494901;
}

.table-hover .table-yellow:hover {
  background-color: #616101;
}

.table-hover .table-yellow:hover > td,
.table-hover .table-yellow:hover > th {
  background-color: #616101;
}

.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: #083140;
}

.table-blue th,
.table-blue td,
.table-blue thead th,
.table-blue tbody + tbody {
  border-color: #083140;
}

.table-hover .table-blue:hover {
  background-color: #08485d;
}

.table-hover .table-blue:hover > td,
.table-hover .table-blue:hover > th {
  background-color: #08485d;
}

.pointLink {
  cursor: pointer;
}
</style>
